import React, { useState } from 'react'
import Spark01 from '../assets/projects/PicsOfProject/Spark01.jpg'
import Spark3 from '../assets/projects/PicsOfProject/Spark3.jpg'
import Spark4 from '../assets/projects/PicsOfProject/Spark4.jpg'
import {SiJavascript, SiBootstrap, SiHtml5} from 'react-icons/si'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const images = [
  `${Spark01}`,
  `${Spark3}`,
  `${Spark4}`,
]

function Spark() {

  const [data, setData] = useState({img: '', i: 0});

  const [open, setOpen] = React.useState(false);
  
  return (
    <div className='w-full h-screen background'>
        {/* Container */}
        
      <div className='max-w-[1400px] h-[780px] w-full m-auto py-16 px-4 group'>

          <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center mt-10'>
            <p className='nameText mx-auto font-bold border-b-4 mt-8 border-pink-600 ml-1'>
                Spark
            </p>
            <h2 className='text-4xl sm:text-6xl font-bold textcolor'>Spark is an social media</h2>
            <p className='textcolor text-1xl sm:text-2xl py-4 max-w-[700px]'>
                Spark is a photo sharing social networking Platforms. The app allows users to upload posts. Users can browse other users, view trending content,
                And user can edit or delet posts.
            </p>
            <h3 className='nameText font-bold mb-4'>
              Tools :
              <span className='mx-4' style={{display: 'inline-flex'}}>
                <SiHtml5 size={'30px'} />
              </span>
              <span className='mx-4' style={{display: 'inline-flex'}}>
                <SiBootstrap size={'35px'} />
              </span>
              <span className='mx-4' style={{display: 'inline-flex'}}>
                <SiJavascript size={'30px'} />
              </span>
            </h3>
            <div>
                <a href="https://spark-soical-media.netlify.app/" target="__blank">
                  <button  className='textmain group mr-4 border-2 px-6 py-3 my-2 buttonBg '>
                      View Work
                  </button>
                </a>
                <a href="https://github.com/Mohammed-M97/Spark-soical-media-app" target="__blank">
                  <button  className='textmain group mr-4 border-2 px-6 py-3 my-2 buttonBg '>
                      View Code
                  </button>
                </a>
            </div>
          </div>
          {data.img &&
            <div className='gallery'>
              <img className='galleryImg' src={data.img} />
            </div>
          }
          <div style={{padding: '10px'}} className='mt-16'>
            <ResponsiveMasonry
                    columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
                >
                    <Masonry gutter='20px'>
                        {images.map((image, i) => (
                            <img
                                key={i}
                                src={image}
                                style={{width: "100%", display: "block", cursor: "pointer"}}
                                alt=""
                                onClick={() => setOpen(true)}
                            />
                        ))}
                    </Masonry>
            </ResponsiveMasonry>

            <Lightbox
              open={open}
              close={() => setOpen(false)}
              slides={[
                { src: `${Spark01}` },
                { src: `${Spark3}` },
                { src: `${Spark4}` },
              ]}
            />
          </div>  
      </div>
          
    </div>
  )
}

export default Spark
