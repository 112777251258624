import React, { useState } from 'react'
import home from '../assets/projects/PicsOfProject/home.jpg'
import login from '../assets/projects/PicsOfProject/login.jpg'
import firebase from '../assets/projects/PicsOfProject/firebase.jpg'
import post from '../assets/projects/PicsOfProject/post.jpg'
import logout from '../assets/projects/PicsOfProject/logout.jpg'
import {SiTypescript, SiFirebase, SiReact} from 'react-icons/si'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const images = [
  `${home}`,
  `${login}`,
  `${firebase}`,
  `${post}`,
  `${logout}`,
]

function Firebace() {

  const [data, setData] = useState({img: '', i: 0});

  const [open, setOpen] = React.useState(false);

  return (

    <div className='w-full h-screen background'>
        {/* Container */}
        
      <div className='max-w-[1400px] h-[780px] w-full m-auto py-16 px-4 group'>

          <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center mt-10'>
            <p className='nameText mx-auto font-bold border-b-4 mt-8 border-pink-600 ml-1'>
                Website hosted by Firebase
            </p>
            {/* <h2 className='text-4xl sm:text-6xl font-bold textcolor'>Spark is an social media</h2> */}
            <p className='textcolor text-1xl sm:text-2xl py-4 max-w-[700px]'>
                A website that allows users to create and share posts, all stored in a Firebase database.
            </p>
            <h3 className='nameText font-bold mb-4'>
              Tools :
              <span className='mx-4' style={{display: 'inline-flex'}}>
                <SiReact size={'30px'} />
              </span>
              <span className='mx-4' style={{display: 'inline-flex'}}>
                <SiTypescript size={'30px'} />
              </span>
              <span className='mx-4' style={{display: 'inline-flex'}}>
                <SiFirebase size={'35px'} />
              </span>
            </h3>
            <div>
                <a href="https://react-firebase-project-2ca21.web.app/" target="__blank">
                  <button  className='textmain group mr-4 border-2 px-6 py-3 my-2 buttonBg '>
                      View Work
                  </button>
                </a>
                <a href="https://github.com/Mohammed-M97/react-firebase-project" target="__blank">
                  <button  className='textmain group mr-4 border-2 px-6 py-3 my-2 buttonBg '>
                      View Code
                  </button>
                </a>
            </div>
          </div>
          {data.img &&
            <div className='gallery'>
              <img className='galleryImg' src={data.img} />
            </div>
          }
          <div style={{padding: '10px'}} className='mt-16'>
            <ResponsiveMasonry
                    columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
                >
                    <Masonry gutter='20px'>
                        {images.map((image, i) => (
                            <img
                                key={i}
                                src={image}
                                style={{width: "100%", display: "block", cursor: "pointer"}}
                                alt=""
                                onClick={() => setOpen(true)}
                            />
                        ))}
                    </Masonry>
            </ResponsiveMasonry>

            <Lightbox
              open={open}
              close={() => setOpen(false)}
              slides={[
                { src: `${home}` },
                { src: `${login}` },
                { src: `${post}` },
                { src: `${logout}` },
                { src: `${firebase}` },
              ]}
            />
          </div>  
      </div>
          
    </div>
  )
}

export default Firebace
