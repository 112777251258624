import Spark01 from '../assets/projects/Spark01.jpg'
import ServiceApp002 from '../assets/projects/ServiceApp002.jpg'
import ChatApp4 from '../assets/projects/PicsOfProject/ChatApp4.jpg'
import home from "../assets/projects/PicsOfProject/home.jpg";

export const data=[
    {
        id:1,
        name:"Spark",
        image:Spark01,
        view:"projects/Spark",
    },
    {
        id:2,
        name:"Services App",
        image:ServiceApp002,
        view:"projects/ServicesApp",
    },
    {
        id:3,
        name:"Chat App",
        image:ChatApp4,
        view:"projects/Chatapp",
    },
    {
        id:4,
        name:"Website hosted by Firebase",
        image:home,
        view:"projects/Firebace",
    },
]