import React, { useState } from 'react'
import ServiceApp3 from '../assets/projects/PicsOfProject/ServiceApp3.jpg'
import ServiceApp5 from '../assets/projects/PicsOfProject/ServiceApp5.jpg'
import ServiceApp8 from '../assets/projects/PicsOfProject/ServiceApp8.jpg'
import ServiceApp9 from '../assets/projects/PicsOfProject/ServiceApp9.jpg'
import ServiceApp10 from '../assets/projects/PicsOfProject/ServiceApp10.jpg'
import ServiceAppSQL from '../assets/projects/PicsOfProject/ServiceAppSQL.jpg'
import { SiBootstrap, SiHtml5, SiPhp, SiMysql, SiJavascript } from 'react-icons/si'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const images = [
  `${ServiceApp3}`,
  `${ServiceApp5}`,
  `${ServiceApp8}`,
  `${ServiceAppSQL}`,
  `${ServiceApp9}`,
  `${ServiceApp10}`
]

function ServicesApp() {

  const [data, setData] = useState({img: '', i: 0});

  const [open, setOpen] = React.useState(false);  
  console.log("hi");
  
  return (
    <div className='w-full h-screen background'>
        {/* Container */}
        
        <div className='max-w-[1400px] h-[780px] w-full m-auto py-16 px-4 group'>
          <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center mt-10'>
              <p className='nameText mx-auto font-bold border-b-4 mt-8 border-pink-600 ml-1'>
                Services App
              </p>
              <h2 className='text-4xl sm:text-6xl font-bold textcolor'>Services is an app for learning new things</h2>
              <p className='textcolor text-1xl sm:text-2xl py-4 max-w-[700px]'>
              Services is an online learning platform that allows users to choose from a variety of courses, each of which is designed to teach a specific skill or topic. Users can also create their own courses, which can be shared with others. The app also includes a dashboard for admins, which allows them to register users, create new courses, and edit existing courses.
              </p>
              <h3 className='nameText font-bold mb-4'>
                Tools :
                <span className='mx-4' style={{display: 'inline-flex'}}>
                  <SiHtml5 size={'30px'} />
                </span>
                <span className='mx-4' style={{display: 'inline-flex'}}>
                  <SiBootstrap size={'35px'} />
                </span>
                <span className='mx-4' style={{display: 'inline-flex'}}>
                  <SiJavascript size={'35px'} />
                </span>
                <span className='mx-4' style={{display: 'inline-flex'}}>
                  <SiPhp size={'30px'} />
                </span>
                <span className='mx-4' style={{display: 'inline-flex'}}>
                  <SiMysql size={'30px'} />
                </span>
              </h3>
              <div>
                  <a href="https://github.com/Mohammed-M97/Services-App" target="__blank">
                    <button  className='textmain group mr-4 border-2 px-6 py-3 my-2 buttonBg '>
                        View Code
                    </button>
                  </a>
              </div>
          </div>
          {data.img &&
            <div className='gallery'>
              <img className='galleryImg' src={data.img} />
            </div>
          }
          <div style={{padding: '10px'}} className='mt-16'>
            <ResponsiveMasonry
                    columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
                >
                    <Masonry gutter='20px'>
                        {images.map((image, i) => (
                            <img
                                key={i}
                                src={image}
                                style={{width: "100%", display: "block", cursor: "pointer"}}
                                alt=""
                                onClick={() => setOpen(true)}
                            />
                        ))}
                    </Masonry>
            </ResponsiveMasonry>

            <Lightbox
              open={open}
              close={() => setOpen(false)}
              slides={[
                { src: `${ServiceApp3}` },
                { src: `${ServiceApp5}` },
                { src: `${ServiceApp8}` },
                { src: `${ServiceAppSQL}` },
                { src: `${ServiceApp9}` },
                { src: `${ServiceApp10}` },
              ]}
            />
          </div>  
        </div>
    </div>
  )
}

export default ServicesApp
